import React, { Component } from "react";

class Home extends Component {
  render() {
    return (
      <div className="home">
        <img
          src="../images/web.png"
          alt="on computer"
          style={{ width: "100vw" }}
        />
      </div>
    );
  }
}

export default Home;
